@media only screen and (max-width: 1600px) {
  .image_center {
    align-items: center !important;
    display: flex !important;
    flex-direction: column !important;
  }
  .width_drawer {
    width: 35% !important;
  }
  .cross_drawer {
    display: block !important;
    margin-top: 10px;
  }
  .admin_modal_pa {
    width: 75% !important;
    padding: 0px 5px 20px 5px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .heading_center {
    text-align: center !important;
  }

  .image_center {
    align-items: center !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .top_options {
    display: flex !important;
    flex-direction: column !important;
    height: 340px !important;
  }

  .top_column {
    display: flex !important;
    flex-direction: column !important;
  }

  .width_full {
    width: 100% !important;
  }

  .width_full_property {
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  .width_full_property_price {
    width: 100% !important;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
  }

  .width_full_status {
    width: 100% !important;
    margin-left: 0px !important;
    margin-top: 20px !important;
  }

  .property_modal_header {
    padding: 20px 0px 12px 5px !important;
  }

  .margin_property {
    margin-right: 5px !important;
    padding-left: 5px !important;
  }

  .property_checkbox {
    width: 95% !important;
  }

  .admin_modal {
    width: 95% !important;
    padding: 20px 5px 20px 5px !important;
  }

  .admin_modal_pa {
    width: 95% !important;
    padding: 0px 5px 20px 5px !important;
  }

  .property_padding {
    padding: 5px !important;
  }
  .cross_drawer {
    display: block !important;
  }
  .width_drawer {
    width: 100% !important;
  }
  .property_Modal_Padding {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .image_Width {
    width: 100px !important;
  }
  .image_Margin {
    margin-right: 10px !important;
    margin-bottom: 5px !important;
  }
  .justify {
    justify-content: center !important;
  }
  .column {
    flex-direction: column !important ;
  }
  .margin {
    margin-bottom: 5px !important;
  }
  .width_agent {
    width: 65% !important;
  }
  .video_property {
    width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .heading_center {
    text-align: center !important;
  }

  .image_center {
    align-items: center !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .top_options {
    display: flex !important;
    flex-direction: column !important;
    height: 340px !important;
  }

  .top_column {
    display: flex !important;
    flex-direction: column !important;
  }

  .width_full {
    width: 100% !important;
  }

  .width_full_property {
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  .width_full_property_price {
    width: 100% !important;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
  }

  .width_full_status {
    width: 100% !important;
    margin-left: 0px !important;
    margin-top: 20px !important;
  }

  .property_modal_header {
    padding: 20px 0px 12px 5px !important;
  }

  .margin_property {
    margin-right: 5px !important;
    padding-left: 5px !important;
  }

  .property_checkbox {
    width: 95% !important;
  }

  .admin_modal {
    width: 95% !important;
    padding: 20px 5px 20px 5px !important;
  }

  .admin_modal_pa {
    width: 95% !important;
    padding: 0px 5px 20px 5px !important;
  }

  .property_padding {
    padding: 5px !important;
  }
  .cross_drawer {
    display: block !important;
  }
  .width_drawer {
    width: 100% !important;
  }
  .property_Modal_Padding {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .image_Width {
    width: 100px !important;
  }
  .image_Margin {
    margin-right: 10px !important;
    margin-bottom: 5px !important;
  }
  .justify {
    justify-content: center !important;
  }
  .column {
    flex-direction: column !important ;
  }
  .margin {
    margin-bottom: 5px !important;
  }
  .width_agent {
    width: 65% !important;
  }
  .video_property {
    width: 100% !important;
  }
}

@media only screen and (max-width: 480px) {
  /* .login_logo {
        text-align: center !important;
    }

    .login_logo img {
        display: inline-block !important;
        vertical-align: middle !important;
    } */

  .heading_center {
    text-align: center !important;
  }

  .image_center {
    align-items: center !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .top_options {
    display: flex !important;
    flex-direction: column !important;
    height: 340px !important;
  }

  .top_column {
    display: flex !important;
    flex-direction: column !important;
  }

  .width_full {
    width: 100% !important;
  }

  .width_full_property {
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  .width_full_status {
    width: 100% !important;
    margin-left: 0px !important;
    margin-top: 20px !important;
  }

  .width_full_property_price {
    width: 100% !important;
    margin-bottom: 20px !important;
    margin-top: 20px !important;
  }

  .property_modal_header {
    padding: 20px 0px 12px 5px !important;
  }

  .margin_property {
    margin-right: 5px !important;
    padding-left: 5px !important;
  }

  .property_checkbox {
    width: 95% !important;
  }

  .admin_modal {
    width: 95% !important;
    padding: 20px 5px 20px 5px !important;
  }

  .admin_modal_pa {
    width: 95% !important;
    padding: 0px 5px 20px 5px !important;
  }

  .property_padding {
    padding: 5px !important;
  }
  .cross_drawer {
    display: block !important;
  }
  .width_drawer {
    width: 100% !important;
  }
  .property_Modal_Padding {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .image_Width {
    width: 100px !important;
  }
  .image_Margin {
    margin-right: 10px !important;
    margin-bottom: 5px !important;
  }
  .justify {
    justify-content: center !important;
  }
  .column {
    flex-direction: column !important ;
  }
  .margin {
    margin-bottom: 5px !important;
  }
  .width_agent {
    width: 65% !important;
  }
  .video_property {
    width: 100% !important;
  }
}

.one-line-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2; /* Number of lines to show */
  font-size: 12px;
  font-weight: bold;
}
